import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact us"
      description="Whether you are planning to renovate an existing property or build a new home, get in touch with us for free consultation and sound building advice."
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Contact us</h1>
        <div className="row">
          <div className="col-lg-9 mx-auto mb-5 text-center">
            <p className="lead">
              Whether you are planning to renovate an existing property or build
              a new home, get in touch with us for free consultation and sound
              building advice. We would love to hear from you.
            </p>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-6 d-flex align-items-center mb-5 mb-lg-0">
            <div className="row">
              <div className="mb-gutter col-sm-6 mb-5 text-center">
                <i className="fas mb-3 fa-2x fa-phone"></i>
                <h5>Phone Number</h5>
                <a href="tel:+022 082 1972">022 082 1972</a>
              </div>
              <div className="mb-gutter col-sm-6 mb-5 text-center">
                <i className="fas mb-3 fa-2x fa-envelope"></i>
                <h5>Email</h5>
                <a href="mailto:james@devennyconstruction.co.nz">
                  james@devennyconstruction.co.nz
                </a>
              </div>
              <div className="mb-gutter col-sm-6 mb-5 mb-sm-0  text-center">
                <i className="fas mb-3 fa-2x fa-map-marker-alt"></i>
                <h5>Location</h5>7 Coradine Street, Masterton
              </div>
              <div className="mb-gutter col-sm-6  text-center">
                <i className="fas mb-3 fa-2x fa-clock"></i>
                <h5>Opening Hours</h5>
                <span>Weekdays: 09:00 – 17:00</span>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mt-4 mt-lg-0">
            <StaticImage
              src="../images/james-devenny.webp"
              alt="James Devenny from Devenny Construction"
            />
          </div>
        </div>
      </div>
    </section>

    <section id="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3013.5514124589854!2d175.65444781568306!3d-40.94749767930704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d41213a7ebae495%3A0x1a23ede79a41300!2s7%20Coradine%20Street%2C%20Masterton%205810!5e0!3m2!1sen!2snz!4v1616908210411!5m2!1sen!2snz"
        frameBorder={0}
        style={{ height: "100%", width: "100%", border: 0 }}
        allowFullScreen
        aria-hidden="false"
        title="Find us on Google maps"
      />
    </section>
  </Layout>
)

export default ContactPage
